<template>
      <div class="container-fluid main-color">
          <!-- start page title -->
          <div class="row">
              <div class="col-12">
                  <div class="page-title-box">
                      <h4 class="page-title">{{ currentRouteName === 'hrd-behavior-question-create'? 'Add Question' : 'Edit Question' }}</h4>
                  </div>
              </div>
          </div>
          <!-- end page title -->
          
          <div class="row">
              <div class="col-lg-12">
                  <place-holder v-if="loading"></place-holder>
                  <div class="card" v-else>
                      <div class="card-body">
                          <form class="parsley-examples">
                              <div class="row mb-3">
                                  <label for="name" class="col-md-2 ms-4 form-label">
                                      Questions
                                      <span class="text-danger">*</span>
                                  </label>
                                  <div class="col-md-6" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                      <input type="text" id="name" name="name" class="form-control" placeholder="Question"
                                          v-model="behavior.name" :class="{
                                              'p-invalid':
                                                  v$.behavior.name.$error || errorFor('name'),
                                          }" />
                                      <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                                          errors: v$.behavior.name.$errors,
                                          value: 'Question',
                                      }"></v-errors>
                                  </div>
  
                                  <div class="col-md-6" v-else-if="hasPermissions(['hrd-behaviour-question-criteria-list'])">
                                      <input type="text" id="name" name="name" class="form-control"
                                          v-model="behavior.name" disabled/>
                                  </div>
  
                              </div>

                              <div class="row mb-3">
                    
                                    <div class="col-lg-2">
                                    <label for="program" class="ms-4 form-label"> 
                                          Program Name 
                                                <span class="text-danger">*</span>
                                    </label>
                                    </div>
                              
                                    <div class="col-md-6 ms-4">
                              
                                    <div class="d-flex col-md-6">
                                          
                                          <div class="d-flex align-items-center me-3">
                                          <input
                                          type="radio"
                                          name="choose"
                                          :checked="behavior.all_check"
                                          @click="
                                          behavior.all_check = 1
                                          behavior.assessment_criteria_id = '';
                                          "
                                          />
                                          <label>All</label>
                                          </div>
                              
                                          <div class="d-flex align-items-center">
                                          <input
                                          type="radio"
                                          name="choose"
                                          :checked="!behavior.all_check"
                                          @click="
                                          behavior.all_check = 0; 
                                          behavior.program_ids = [];
                                          behavior.assessment_criteria_id = '';
                                          "
                                          />
                                          <label>Select</label>
                                          </div>
                              
                                    </div>
                              
                                    <v-select  
                                    v-show="!behavior.all_check"
                                    name="program"
                                    id="program"
                                    :options="programList" 
                                    label="name"
                                    :reduce="(option)=>option.id"
                                    :loading="!programList.length"
                                    :class="{
                                    'p-invalid': this.v$.behavior.program_ids.$error,
                                    }"
                                    v-model="behavior.program_ids"
                                    :selectable="(data) => selectedProgram(data)"
                                    multiple
                                    @option:selected="behavior.assessment_criteria_id = ''"
                                    @option:deselected="behavior.assessment_criteria_id = ''"
                                    />
                              
                                    <v-errors v-show="!behavior.all_check"
                                    :vuelidateErrors="{
                                    errors: this.v$.behavior.program_ids.$errors,
                                    value: 'Program',
                                    }"
                                    >
                                    </v-errors>
                              
                                    </div>
                              
                              </div>
  
                              <div class="row mb-3">
                                  <label for="level" class="col-md-2 ms-4 form-label">
                                      Assessment Criteria
                                      <span class="text-danger">*</span>
                                  </label>
                                  <div class="col-md-6" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                      <v-select class="custom-selectCSS" v-model="behavior.assessment_criteria_id"
                                          label="name" :options="filterAssessmentCriteria" :clearable="false"
                                          :selectable="(options) => isSelectableCriteria(options)"
                                          :reduce="(name) => name.id" :class="{
                                              'p-invalid':
                                                  v$.behavior.assessment_criteria_id.$error || errorFor('assessment_criteria_id'),
                                          }">
                                      </v-select>
                                      <v-errors :serverErrors="errorFor('assessment_criteria_id')" :vuelidateErrors="{
                                          errors: v$.behavior.assessment_criteria_id.$errors,
                                          value: 'Evaluating Criteria',
                                      }"></v-errors>
                                  </div>
  
                                   <div class="col-md-6" v-else-if="hasPermissions(['hrd-behaviour-question-criteria-list'])">
                                      <v-select class="custom-selectCSS" v-model="behavior.assessment_criteria_id"
                                          label="name" :options="evaluatingCriteria" :clearable="false"
                                          disabled >
                                      </v-select>
                                      
                                  </div>
  
                              </div>
  
                              <div class="float-end me-5" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                  <button v-show="!loading" type="button" @click="addAnswer()"
                                      class="btn btn-sm btn-blue waves-effect waves-light mb-2">
                                      Add Answer
                                  </button>
                              </div>
  
                              <div class="row mt-5 text-center">
                                  <div class="d-flex">
                                      <div class="col-md-5">
                                          <label for="name" class="form-label">
                                              Answer
                                              <span class="text-danger">*</span>
                                          </label>
                                      </div>
                                      <div class="col-md-5">
                                          <label for="name" class="form-label">
                                              Score
                                              <span class="text-danger">*</span>
                                          </label>
                                      </div>
                                  </div>
                              </div>
  
                              <div class="row mb-4" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                  <div v-for="(item, index) in behavior.answers" :key="index" class="d-flex m-2">
                                      <div class="col-md-4 me-5 ms-4">
                                          <input type="text" id="name" name="name" class="form-control" placeholder="Answer"
                                              v-model="item.name" />
                                          <!-- :class="{
                                                  'p-invalid':
                                                      v$.item.name.$error || errorFor('name'),
                                              }" -->
                                          <!-- <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                                              errors: v$.item.name.$errors,
                                              value: 'Answer',
                                          }"></v-errors> -->
                                      </div>
                                      <div class="col-md-4 me-4">
                                          <input type="number" id="score" name="score" class="form-control"
                                              oninput="this.value = !!this.value && Math.abs(this.value) >= 1 && Math.abs(this.value) <=5 ? Math.abs(this.value) : null"
                                              placeholder="Score" v-model="item.score" />
                                          <!-- :class="{
                                                  'p-invalid':
                                                      v$.item.score.$error || errorFor('name'),
                                              }" -->
                                          <!-- <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                                              errors: v$.item.score.$errors,
                                              value: 'Score',
                                          }"></v-errors> -->
                                      </div>
                                      <div class="col-md-2" v-show="index != 0">
                                          <button v-show="!loading" type="button" @click="removeAnswer(index)"
                                              class="btn btn-sm btn-danger waves-effect waves-light mb-2">
                                              Remove
                                          </button>
                                      </div>
                                  </div>
                              </div>
  
  
                                 <div class="row mb-4" v-else-if="hasPermissions(['hrd-behaviour-question-criteria-list'])">
                                  <div v-for="(item, index) in behavior.answers" :key="index" class="d-flex m-2">
                                      <div class="col-md-4 me-5 ms-4">
                                          <input type="text" id="name" name="name" class="form-control" placeholder="Answer"
                                              v-model="item.name" disabled/>
                                          <!-- :class="{
                                                  'p-invalid':
                                                      v$.item.name.$error || errorFor('name'),
                                              }" -->
                                          <!-- <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                                              errors: v$.item.name.$errors,
                                              value: 'Answer',
                                          }"></v-errors> -->
                                      </div>
                                      <div class="col-md-4 me-4">
                                          <input type="number" id="score" name="score" class="form-control"
                                              oninput="this.value = !!this.value && Math.abs(this.value) >= 1 && Math.abs(this.value) <=5 ? Math.abs(this.value) : null"
                                              placeholder="Score" v-model="item.score" disabled/>
                                          <!-- :class="{
                                                  'p-invalid':
                                                      v$.item.score.$error || errorFor('name'),
                                              }" -->
                                          <!-- <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                                              errors: v$.item.score.$errors,
                                              value: 'Score',
                                          }"></v-errors> -->
                                      </div>
                                      <div class="col-md-2" v-show="index != 0" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                          <button v-show="!loading" type="button" @click="removeAnswer(index)"
                                              class="btn btn-sm btn-danger waves-effect waves-light mb-2">
                                              Remove
                                          </button>
                                      </div>
                                  </div>
                              </div>
  
                              <div class="row mb-3" v-if="currentRouteName === 'hrd-behavior-question-update'">
                                  <label for="status" class="col-md-2 ms-4 form-label">
                                      Status
                                  </label>
                                  <div class="col-md-6" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                      <select name="status" v-model="status" class="form-control">
                                          <option :value="1">Active</option>
                                          <option :value="0">Inactive</option>
                                      </select>
                                  </div>
  
                                  <div class="col-md-6" v-else-if="hasPermissions(['hrd-behaviour-question-criteria-list'])">
                                      <select name="status" v-model="status" class="form-control" disabled>
                                          <option :value="1">Active</option>
                                          <option :value="0">Inactive</option>
                                      </select>
                                  </div>
  
                              </div>
  
                              <div class="row mb-3" v-if="!status">
                                  <label for="remark" class="col-md-2 ms-4 form-label">
                                      Remark
                                  </label>
                                  <div class="col-md-6" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                      <textarea type="text" id="remark" name="remark" class="form-control"
                                          placeholder="Remark" v-model="remark" :class="{
                                              'p-invalid':
                                                  v$.remark.$error || errorFor('remark'),
                                          }">
                                      </textarea>
                                      <v-errors :serverErrors="errorFor('remark')" :vuelidateErrors="{
                                          errors: v$.remark.$errors,
                                          value: 'Remark',
                                      }"></v-errors>
                                  </div>
  
                                  <div class="col-md-6" v-else-if="hasPermissions(['hrd-behaviour-question-criteria-list'])">
                                      <textarea type="text" id="remark" name="remark" class="form-control"
                                          placeholder="Remark" v-model="remark" disabled >
                                  </textarea>
                                      
                                  </div>
  
                              </div>
                              <!-- end row -->
                              <div class="row" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                  <div class="col-md-12">
                                      <div class="text-center mt-3 mb-3">
                                          <router-link :to="{ name: 'hrd-behavior-question' }">
                                              <button type="button" class="btn w-sm btn-secondary me-5">
                                                  Cancel
                                              </button>
                                          </router-link>
                                          <button :disabled="isLoading ? true : false" type="button"
                                              class="btn w-sm btn-success waves-effect waves-light px-3" @click="
                                                  currentRouteName === 'hrd-behavior-question-update'
                                                      ? updateBehaviorQuestion()
                                                      : createBehaviorQuestion()
                                                  ">
                                              <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span>
                                              {{ isLoading == true ? 'Loading...' : 'Save' }}
                                          </button>
                                      </div>
                                  </div>
                                  <!-- end col -->
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </template>
    
  <script>
  import axios from "axios";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { useToast } from "vue-toastification";
  import useVuelidate from "@vuelidate/core";
  import { required, requiredIf } from "@vuelidate/validators";
  import validationErrors from "../../../mixins/validationErrors";
  
  //Mixins
  import userHasPermissions from "../../../mixins/userHasPermissions";
  
  export default {
      setup() {
          const toast = useToast();
          return { v$: useVuelidate(), toast };
      },
      components: {
          vSelect,
      },
      mixins: [validationErrors, userHasPermissions],
      data() {
          return {
              behavior: {
                  name: "",
                  program_ids : [],
                  assessment_criteria_id: "",
                  all_check : 1,
                  answers: [
                      {
                          name: "",
                          score: ""
                      }
                  ]
              },
              programList : [],
              program_id_list: [],
              assessmentCriteria: [],
              status: 1,
              remark: "",
              baseUrl: process.env.VUE_APP_BASE_URL,
              baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
              loading: false,
              isLoading: false
          };
      },
      validations() {
          return {
              behavior: {
                  name: { required },
                  program_ids : this.behavior.all_check ? '' :{ required },
                  assessment_criteria_id: { required },
                  // answers: [
                  //     {
                  //         name: { required },
                  //         score: { required },
                  //     }
                  // ]
              },
              remark: {
                  required: requiredIf(() => {
                      return !this.status;
                  })
              },
          };
      },
      methods: {
            selectedProgram(option) {

            return (!this.behavior.program_ids.length )  ? true :
            this.behavior.program_ids.find((p) => p.id == option.id) === undefined

            },
          isSelectableCriteria(options) {
              if(!this.behavior.assessment_criteria_id || !options) return true;
              return this.behavior.assessment_criteria_id != options.id;
          },
          async getPrograms() {

            await axios.get(`${this.baseUrl}admin/v1/programs`)
                  .then((res) => {
                        let data = res.data.data
                        this.programList = data
                        this.program_id_list = data.map((val) => val.id )
                  })

            },
          async getAssessmentCriteria() {
                  this.loading = true;
                  await axios.get(`${this.baseUrl}admin/v2/assessment-criteria?status=1&type=Orientation`)
                  .then((res) =>{
                        let data = res.data.data
                        this.assessmentCriteria  =  data
                        this.loading = false;
                        
                  })              
            },
          addAnswer() {
              if (this.behavior.answers.length == 5) {
                  this.toast.error("Maximum Answer Limit is 5 !");
                  return
              } else {
                  this.behavior.answers.push({
                      name: "",
                      score: ""
                  })
              }
          },
          removeAnswer(index) {
              this.behavior.answers.splice(index, 1);
          },
          async getBehaviorQuestionById() {
              this.loading = true;
              await axios
                  .get(`${this.baseUrl}admin/v2/hrd-behavior-question/${this.$route.params.id}`)
                  .then((response) => {
                      const result = response.data.data;
                      this.behavior.name = result.name;
                      this.behavior.program_ids = result.programs.map( (val) => val.id )
                      this.behavior.assessment_criteria_id = result.assessment_criteria_id
                      this.behavior.all_check = result.all_check
                      this.behavior.answers = result.hrd_behavior_answers
                      this.remark = result.remark
                      this.status = result.status

                  })
                  .catch(() => {
                      this.toast.error("Not Found Criteria!");
                  });
              this.loading = false;
          },
  
          checkEmptyValue(array) {
              let answerAndScore = Object.values(array);
              if (answerAndScore.includes(null) || answerAndScore.includes("")) {
                  return false;
              } else {
                  return true;
              }
          },
  
          async createBehaviorQuestion() {
       
              const isFormCorrect = await this.v$.$validate();
              if (!isFormCorrect) return;
              if(this.behavior.all_check){
                  this.behavior.program_ids = this.program_id_list
                }    
              let checkAnswer = [];
              this.behavior.answers.forEach((array) => {
                  let answer = this.checkEmptyValue(array);
                  checkAnswer.push(answer);
              });
              if (checkAnswer.includes(false)) {
                  this.toast.error("Please Fill All Input!");
                  return;
              }
             
              this.isLoading = true;
      
              await axios
                  .post(`${this.baseUrl}admin/v2/hrd-behavior-question`, this.behavior)
                  .then(() => {
                      this.$router.push({ name: "hrd-behavior-question" });
                      this.toast.success("Successfully Created Behavior Question!");
                      this.isLoading = false;
                  })
                  .catch((error) => {
                      this.errors = error.response;
                      this.toast.error("Something Went Wrong!");
                      this.isLoading = false;
                  });
          },
  
          async updateBehaviorQuestion() {
              const isFormCorrect = await this.v$.$validate();
              if (!isFormCorrect) return;
              
              if(this.behavior.all_check){
                  this.behavior.program_ids = this.program_id_list
                } 
              let checkAnswer = [];
              this.behavior.answers.forEach((array) => {
                  let answer = this.checkEmptyValue(array);
                  checkAnswer.push(answer);
              });
              if (checkAnswer.includes(false)) {
                  this.toast.error("Please Fill All Input!");
                  return;
              }
  
              if(this.status == 1) this.remark = null;
              let data = {
                  name: this.behavior.name,
                  assessment_criteria_id: this.behavior.assessment_criteria_id,
                  answers: this.behavior.answers,
                  all_check : this.behavior.all_check,
                  program_ids : this.behavior.program_ids,
                  status: this.status,
                  remark: this.remark
              }
              this.isLoading = true;
              axios
                  .put(
                      `${this.baseUrl}admin/v2/hrd-behavior-question/${this.$route.params.id}`,
                      data
                  )
                  .then(() => {
                      this.$router.push({ name: "hrd-behavior-question" });
                      this.toast.success("Successfully Behavior Question Updated!");
                      this.isLoading = false;
                  })
                  .catch((error) => {
                      this.errors = error.response.data.errors;
                      this.toast.error("Something Went Wrong!");
                      this.isLoading = false;
                  });
          },
      },
      async created() {
            await this.getPrograms();
            await this.getAssessmentCriteria();
            if (this.currentRouteName == "hrd-behavior-question-update") {
                  await this.getBehaviorQuestionById();
            }
      },
      computed: {
          currentRouteName() {
              return this.$route.name;
          },
          filterAssessmentCriteria() {
            if(this.behavior.all_check) {
            return  this.assessmentCriteria.filter((val) => {
                  return val.all_check
          })}else{
          return this.assessmentCriteria.filter((val)  => {
				if(this.behavior.program_ids.length){
					let p_ids = val.programs.map((p) => p.id)
                  let flag = true;
                       Object.values(this.behavior.program_ids).some((p) => {
                          if(! p_ids.includes(p)){
                            flag = false;
                          }
        
                      })
                    return flag;
				}			
                  
          })
        }
            },
      },
  };
  </script>
    
  <style>
  .custom-selectCSS {
      --vs-dropdown-min-width: 160px;
      --vs-dropdown-max-height: 200px;
  }
  </style>
    